import '../Styles/AlertStyles.scss';

const AlertInfo = (props) => {
    return (
        <div className="alert alert-info">
            <strong>Info!</strong> {props.infoMessage}
        </div>
    )   
}

export default AlertInfo;