import '../Styles/widgetstyle.scss';

const ThankYou = (thankYouProps) => {
    return (
    <div className="padding-top-10">
        <p>{thankYouProps.thankYouProps.thankYouText}</p>
    </div>
    );
};

export default ThankYou;