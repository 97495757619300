import '../Styles/widgetstyle.scss';

const AddYourScore = (addYourScoreProps) => {
    return (
        <div className="add-your-score" onClick={addYourScoreProps.addYourScoreProps.nextStep}>
        Add
        <br/>
        Your
        <br/>
        Score
    </div>
    );
};

export default AddYourScore;