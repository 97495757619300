import { useState } from 'react';

// Styles
import '../Styles/widgetstyle.scss';

// Services
import { AddUserAnswer } from '../Services/PostService';

// Components
import ThankYou from './ThankYou';
import YourScore from './YourScore';
import AverageScore from './AverageScore';
import AddYourScore from './AddYourScore';
import HasTriedBefore from './HasTriedBefore';

// Models
import { UserAnswer } from '../Models/QuestionModels';
import { ThankYouProps } from '../Models/ThankYouProps';
import { YourScoreProps } from '../Models/YourScoreProps';
import { AddYourScoreProps } from '../Models/AddYourScoreProps';
import { AverageScoreProps } from '../Models/AverageScoreProps';
import { HasTriedBeforeProps } from '../Models/HasTriedBeforeProps';

const AnswerComponent = (props) => {
    const [showYesNoStep, setShowYesNoStep] = useState<boolean>(false);
    const [showScoreStep, setShowScoreStep] = useState<boolean>(false);
    const [averageScore, setAverageScore] = useState<number>(props.question.averageScore);
    const [hasTriedBefore, setHasTriedBefore] = useState<boolean>(props.question.hasTriedBefore);
    const [showThankYouStep, setShowThankYouStep] = useState<boolean>(props.question.userHasAnswered);
    const [showAddYourScoreStep, setShowAddYourScoreStep] = useState<boolean>(!props.question.userHasAnswered);

    const moveNextStep = (): void => {
        setShowAddYourScoreStep(false);
        setShowYesNoStep(true);
    }

    const setAnswerYesNo = (hasTriedBefore: boolean): void => {
        setHasTriedBefore(hasTriedBefore);
        setShowYesNoStep(false);
        setShowScoreStep(true);
    } 

    const addNewScore = (score: number): void => {
        pushUserAnswer(score);
        setShowScoreStep(false);
        setShowThankYouStep(true);
    }

    async function pushUserAnswer(score: number) {
        const userAnswer = {
            userId: props.userId,
            userScore: score,
            questionId: props.question.id,
            hasTriedBefore: hasTriedBefore
        } as UserAnswer;

        await AddUserAnswer(userAnswer)
        .then((data) => setAverageScore(data))
    }

    const addYourScoreProps = {
        nextStep: moveNextStep,
        nextStepText: "Add Your Score"
    } as AddYourScoreProps;

    const averageScoreProps = {
        averageScore: averageScore,
        averageText: "Average"
    } as AverageScoreProps;

    const hasTriedBeforeProps = {
        hasTriedBeforeText: "Have you tried this before?",
        answerYesNo: setAnswerYesNo
    } as HasTriedBeforeProps;

    const yourScoreProps = {
        yourScoreText: "Your score:",
        scoreRange: props.question.scoreRange,
        addNewScore: addNewScore
    } as YourScoreProps;

    const thankYouProps = {
        thankYouText: "Thank You!"
    } as ThankYouProps;

    return (
        <>
        <div className="content-container">
            <ul className="flex-container">
                <li id="average-score" className="flex-item average-score">
                    <AverageScore averageScoreProps={averageScoreProps}/>
                </li>
                <li className="flex-item your-score">
                    <div id="your-score" className="small-text">
                        {showAddYourScoreStep &&
                            <AddYourScore addYourScoreProps={addYourScoreProps}/>
                        }
                        {showYesNoStep &&
                            <HasTriedBefore hasTriedBeforeProps={hasTriedBeforeProps}/>
                        }
                        {showScoreStep &&
                            <YourScore yourScoreProps={yourScoreProps}/>
                        }
                        {showThankYouStep &&
                            <ThankYou thankYouProps={thankYouProps}/>
                        }
                    </div>
                </li>
            </ul>
        </div>
        </>
    );
};

export default AnswerComponent;