import { UserAnswer } from '../Models/QuestionModels';

export function AddUserAnswer(userAnswer: UserAnswer) : Promise<number>
{
    const requestOptions = {
        method: 'POST',
        headers: { 'content-type': 'application/json'},
        body: JSON.stringify(userAnswer)
    }
    // Locally hosted service, no remote accessible service exists yet
    return fetch('http://danielbutler.uk/Question/Questions', requestOptions)
    .then((response) => response.json());
};