import '../Styles/widgetstyle.scss';

const YourScore = (yourScoreProps) => {
    
    let scoreOptions = [] as any;

    // This 'widget' is going to be shared eventually, because of this we
    // cannot be sure what the score range will actually be as
    // some people may want less options, this accounts for that scenario
    for (let score = 0; score < yourScoreProps.yourScoreProps.scoreRange+1; score++){
        scoreOptions.push(<button className="inverse-transparent-small" key={score} value={score} onClick={() => yourScoreProps.yourScoreProps.addNewScore(score)}>{score}</button>)
    }

    return (
        <div>
        {yourScoreProps.yourScoreProps.yourScoreText}
        <br/>
        {scoreOptions}
    </div>
    );
};

export default YourScore;