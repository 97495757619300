import '../Styles/widgetstyle.scss';

const HasTriedBefore = (hasTriedBeforeProps) => {
    return(
    <div>
        {hasTriedBeforeProps.hasTriedBeforeProps.hasTriedBeforeText}
        <br/>
        <button className="inverse-transparent-large" onClick={() => hasTriedBeforeProps.hasTriedBeforeProps.answerYesNo(true)}>Yes</button> <button className="inverse-transparent-large" onClick={() => hasTriedBeforeProps.hasTriedBeforeProps.answerYesNo(false)}>No</button>
    </div>
    );
};

export default HasTriedBefore;