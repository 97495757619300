import QuestionInstance from './Question';

const WrapperComponent = () => {
    return (
        <>
            <div className="wrapper">
                <QuestionInstance />
            </div>
        </>
    )
}

export default WrapperComponent;