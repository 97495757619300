import { useState, useEffect } from "react";

// Services
import { GetQuestions } from "../Services/FetchService";

// Components
import AnswerComponent from "./Answer";

// Models
import { Question } from "../Models/QuestionModels";
import AlertInfo from "./AlertInfo";

// Styles
import '../Styles/Buttons.scss';

const QuestionInstance = () => {
	const [questionList, setQuestions] = useState<Question[]>([]);
	const [questionsLoaded, setQuestionsLoaded] = useState<boolean>(false);
	const [showInvalidUserError, setShowInvalidUserError] =
		useState<boolean>(false);
	const [userId, setUserId] = useState<number>(0);

	const invalidUserMessage =
		"Invalid UserId, please enter any number greater than 0.";

	// useEffect(() => {
	//      GetQuestionList();
	// }, [])

	const userIdChange = (e) => {
		setUserId(e.target.value);
	};

	const getQuestionsForUser = () => {
		GetQuestionList();
	};

	async function GetQuestionList() {
		if (!userId || userId == 0 || isNaN(userId)) {
			setShowInvalidUserError(true);
			return;
		}

		setShowInvalidUserError(false);

		setQuestions([]);

		await GetQuestions(userId)
			.then((data) => setQuestions(data.questionList))
			.then((data) =>
				data !== null ? setQuestionsLoaded(true) : setQuestionsLoaded(false)
			);
	}

	return (
		<>
			<br />
			<hr />
			<br />
			<br />
			<a className="btn btn-primary-inverse margin-10" href="../../index.html">
				Discover Widget
			</a>
			<a
				className="btn btn-primary-inverse margin-10"
				href="../../DiscoverManagement/ContentManagement/index.html"
			>
				Discover Management
			</a>
			<br />
			<br />
			<br />
			<hr />
			<div className="inline-button-input">
				<input
					className="input-userid"
					type="text"
					onChange={userIdChange}
					placeholder="Any User Id"
				/>
				<input
					type="button"
					className="btn-userid"
					onClick={() => getQuestionsForUser()}
					value="Load Questions"
				/>
			</div>
			{showInvalidUserError && <AlertInfo infoMessage={invalidUserMessage} />}
			{questionsLoaded &&
				questionList.map((question) => {
					return (
						<div key={question.id} className="question-wrapper">
							<div className="row">
								<div className="column-3">
									<AnswerComponent question={question} userId={userId} />
								</div>
								<div className="column-9">
									<p>{question.questionText}</p>
								</div>
							</div>
						</div>
					);
				})}
		</>
	);
};

export default QuestionInstance;
