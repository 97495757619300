import '../Styles/widgetstyle.scss';

const AverageScore = (averageScoreProps) => {
    return (
        <>
        <div id="average-score-number" className="large-text">
            {averageScoreProps.averageScoreProps.averageScore}
        </div>
        <br/>
        <div className="medium-text">
            {averageScoreProps.averageScoreProps.averageText}
        </div>
        </>
    );
};

export default AverageScore;